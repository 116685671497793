import {useMemo} from 'react';
import {useWeb3React} from '@web3-react/core';
import {useSigner} from "./useSigner";
import {ChainId, ContractAddressBook} from "../constants/networks";
import {MazuriContract} from "./generated";

export function useContract() {
  const { signer, isSignerReady } = useSigner();
  const web3React = useWeb3React()

  const chainId: ChainId = web3React.chainId || ChainId.SEPOLIA_TESTNET

  const address = ContractAddressBook[chainId] as string;
  // const provider = ProviderAddressBook[chainId] as ethers.providers.Provider;
  // console.log(`chain ID`, provider)
  return useMemo(() => {
    const isReady = isSignerReady(signer);

    if (isReady && !!signer) {
      return MazuriContract.connect(address, signer);
    }
    return MazuriContract.connect(address, signer);
    // return MazuriContract.connect(address, provider);
  }, [address, isSignerReady, signer]);
}
