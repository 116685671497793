import {useCallback, useEffect} from "react";
import {useContract} from "../../../blockchain/useContract";
import {handleError} from "../../ErrorHandler/ErrorHandler";
import {ChainId} from "../../../constants/networks";
import {useWeb3React} from "@web3-react/core";
import {useServerConstants} from "../../../hooks/useServerConstants";

export const useBalance = () => {
  const contract = useContract()
  const web3React = useWeb3React()
  const {data: constants} = useServerConstants()

  const chainId: ChainId = web3React.chainId || ChainId.ETH_MAINNET

  useEffect(() => {
    if(chainId !== ChainId.ETH_MAINNET) {
      handleError({id: '0', title: 'Network error', message: "Unsupported network. <br/> Please change network in metamask <br/>Supported Networks: <br/> <b><b>ETHEREUM mainnet network</b></b>"})
    }
  }, [chainId])

  const refillBalance = useCallback(async(amount: string) => {
    try {
      if(chainId !== ChainId.ETH_MAINNET) {
        handleError({id: '0', title: 'Network error', message: "Unsupported network. <br/> Please change network in metamask <br/>Supported Networks: <br/> <b><b>ETHEREUM mainnet network</b></b>"})
        return
      }
      if(!constants?.CurrentDepositWalletAddress) return
      const res = await contract.transfer(constants.CurrentDepositWalletAddress, amount)
      await res.wait()
    } catch (e: any) {
      const error = e.response?.data
      if(!error) {
        console.log(`ERROR`, e)
        return
      }
      console.log(`ERROR`, e)
      handleError({id: error.code, title: 'error', message: error.message || "unexpected error"})
    }
  }, [contract, chainId, constants?.CurrentDepositWalletAddress])


  return {
    refillBalance
  }
}
