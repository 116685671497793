export enum ChainId {
  ETH_MAINNET = 1,
  SEPOLIA_TESTNET = 11155111,
}


export type ContractAddress = {
  [chainId in ChainId]?: string;
};

export const ContractAddressBook: ContractAddress = {
  [ChainId.SEPOLIA_TESTNET]: '0x5fc1931cAd1aD414675bE14fee59454736Aba4Fa',
  [ChainId.ETH_MAINNET]: "0xa98a8a74aa8d6f41f9e498a084548c4db63c8f01"

  // [ChainId.ETH_MAINNET]: '0x957a8342ADa13145517979861D759bFa1CEBCD7C',
  // [ChainId.BSC_MAINNET]: '0xb6D3d9FC8CC300b162049c6f78016a614c0349a1',
  // [ChainId.POLYGON_MAINNET]: '0x679647EE70f5d3dc8155B67FaAb6d886a4648dCC',
};

