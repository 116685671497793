import {Base} from "./Base";
import {ApiResponse} from "./types/api";
import {CodeVerificationResponse, ProfileForm, VerificationPayload} from "./types/User";
import {handleError} from "../features/ErrorHandler/ErrorHandler";
import { ChainId } from "../constants/networks";

export default class User extends Base {
  async getEmailCode() {
    try {
      const response = await this.request.post<ApiResponse<CodeVerificationResponse>>(
        `${this.baseUrl}/user/verification/email`
      )

      return response.data.data
    } catch (e: any) {
      const error = e.response.data
      handleError({id: error.code, title: 'error', message: error.error || "unexpected error"})
    }

  }

  async getPhoneCode() {
    const response = await this.request.post<ApiResponse<CodeVerificationResponse>>(
      `${this.baseUrl}/user/verification/phone`
    )

    return response.data.data
  }


  async verificationCheck(payload: VerificationPayload) {
    try {
      const response = await this.request.post<ApiResponse<any>>(`${this.baseUrl}/user/verification/check`, payload)

      return response.data.success
    } catch (e: any) {
      const error = e.response.data
      handleError({id: error.code, title: 'error', message: error.error || "unexpected error"})
    }
  }

  async getConstants() {
    const response = await this.request.get<ApiResponse<any>>(`${this.baseUrl}/public/constants`)
    return response.data.data
  }

  async balanceWithdraw(network: string, amount: string) {
    try {
      if(network !== String(ChainId.ETH_MAINNET)) {
        handleError({id: '0', title: 'Network error', message: "Unsupported network. <br/> Please change network in metamask <br/>Supported Networks: <br/> <b><b>ETHEREUM mainnet network</b></b>"})
        return
      }
      const response = await this.request.post<ApiResponse<any>>(`${this.baseUrl}/user/withdraw`, {
        network,
        amount
      })
      return response.data.data
    } catch (e: any) {
      const error = e.response.data
      handleError({id: error.code, title: 'error', message: error.error || "unexpected error"})
    }

  }

  async updateProfile(payload: ProfileForm) {
    try {
      const response = await this.request.patch<ApiResponse<any>>(`${this.baseUrl}/user/whoami`, payload)
      return response.data.data
    } catch (e: any) {
        const error = e.response.data
        return {id: error.code, title: 'error', message: error.error || "unexpected error"}
    }
  }

  async getDepositAdress():Promise<any> {
    try {
      const response = await this.request.get<ApiResponse<{address: string}>>(`${this.baseUrl}/user/deposit-address`)
      
      return response.data.data
    } catch (e: any) {
      const error = e.response.data
      handleError({id: error.code, title: 'Error', message: error.error})
      return new Error(error.error)
    }
  }
}
